import React from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import getGraphqlImage from '../../utils/get-graphql-image';
import Img from 'gatsby-image';

const ImageGrid = ({ className, images }) => {
  return (
    <section className={classNames('image-grid', className)}>
      <div className="image-grid__inner container">
        <div className="image-grid__items">
          {images.map(imageObj => {
            const image = getGraphqlImage(imageObj);
            const { alt_tag: altTag, slug } = imageObj;
            const cssClass = `image-grid__item image-grid__item--${slug}`;

            return (
              <div className={cssClass} key={uniqid()}>
                <Img className="image-grid__image" fluid={image} alt={altTag} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ImageGrid;
