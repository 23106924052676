import React from 'react';
import classNames from 'classnames';
import getGraphqlImage from '../../utils/get-graphql-image';
import Img from 'gatsby-image';

const AboutBody = ({ className, body, imageOne, imageTwo, imageThree }) => {
  const imageOneObj = getGraphqlImage(imageOne);
  const imageTwoObj = getGraphqlImage(imageTwo);
  const imageThreeObj = getGraphqlImage(imageThree);

  return (
    <section className={classNames('about-body', className)}>
      <div className="about-body__inner container">
        <div className="about-body__content-wrapper">
          <div className="about-body__body" dangerouslySetInnerHTML={{ __html: body }} />
          {(imageOneObj || imageTwoObj || imageThreeObj) && (
            <div className="about-body__aside">
              {imageOneObj && <Img className="about-body__image about-body__image--image-one" fluid={imageOneObj} />}
              {imageTwoObj && <Img className="about-body__image about-body__image--image-two" fluid={imageTwoObj} />}
              {imageThreeObj && (
                <Img className="about-body__image about-body__image--image-three" fluid={imageThreeObj} />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
