import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { SINGLE } from '../utils/constants';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';
import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import AboutBody from '../components/molecules/about-body';
import ImageGrid from '../components/molecules/image-grid';
import FormRenderer from '../components/organisms/form-renderer';

const modules = {
  PageIntro: Intro,
  Body: AboutBody,
  ImageGrid,
  Form: FormRenderer,
};

const About = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_page);

  const embeds = [
    {
      key: 'form',
      type: SINGLE,
    },
  ];

  const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
  const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      <PageHeader title={data.page.title} />
      {renderFlexibleContent(flexibleContent, modules, {}, embeds)}
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
      acf {
        flexible_content_page {
          module: __typename
          ... on WordPressAcf_page_intro {
            title
            body
          }
          ... on WordPressAcf_body {
            body
            image_one {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 443) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            image_two {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 443) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            image_three {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 443) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
          ... on WordPressAcf_image_grid {
            images {
              alt_text
              slug
              localFile {
                childImageSharp {
                  sizes(maxWidth: 200) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
          ... on WordPressAcf_form {
            form {
              wordpress_id
              acf {
                title
                subtitle
                introduction
                cta_button_text
                note
                mailchimp_url
                mailchimp_user_id
                mailchimp_list_id
                success_message
                generic_error_message
                already_subscribed_error_message
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
